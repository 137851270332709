<template>
  <div>
    <div
      v-if="showSignUpLink"
      class="pt-6 pb-12 text-center"
    >
      <a
        href="/sign-up"
        class="text-platform-link hover:text-platform-link-hover hover:font-semibold cursor-pointer"
      >
        {{ 'login.sign-up' | trans }}
      </a>
    </div>
    <div
      class="flex items-center justify-center"
    >
      <div class="flex items-center justify-center border-gray-200 my-4 font-light border-t mx-auto p-5">
        <div
          class="border-r text-platform-text-color px-4 border-gray-200"
        >
          {{ 'login-footer.sales' | trans }}
        </div>
        <div
          class="border-r text-platform-text-color px-4 border-gray-200"
        >
          {{ 'login-footer.support' | trans }}
        </div>
        <div
          class="px-4 text-primary-700 "
          v-html="translate('login-footer.network')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginFooter",
  props: {
    showSignUpLink: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
}
</script>
